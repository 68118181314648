export enum OrderStatus {
  PENDING = 1, // Client
  CANCELED = 2, // Client - Before two hours
  APPROVED = 3, // Admin or Staff - Assigning to Staff
  REJECTED = 4, // Admin - Rejection reason
  ON_THE_WAY = 5, // Staff - Estimated time
  STARTED = 6, // Staff - Image before
  COMPLETED = 7, // Staff - Image after
  PAID = 8, // Client or Staff - Cash in-hand
  CARD_DECLINED = 9, // Client or Staff - Cash in-hand
}

export enum OrderPaymentMethod {
  CASH = 1,
  CARD = 2,
}

export enum OrderUserType {
  CLIENT = 1,
  STAFF = 2,
}

// Todo: Check Order Progress Status Enum
export enum OrderProgressStatus {
  PENDING = 1, // Client
  CANCELED = 2, // Client - Before two hours
  APPROVED = 3, // Admin or Staff - Assigning to Staff
  REJECTED = 4, // Admin - Rejection reason
  ON_THE_WAY = 5, // Staff - Estimated time
  STARTED = 6, // Staff - Image before
  COMPLETED = 7, // Staff - Image after
  PAID = 8, // Client or Staff - Cash in-hand
  CARD_DECLINED = 9, // Client or Staff - Cash in-hand
}

// Status Orders Colors
export const getOrderStatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.PENDING:
      return 'gray'
    case OrderStatus.CANCELED:
      return 'gray'
    case OrderStatus.APPROVED:
      return 'green'
    case OrderStatus.REJECTED:
      return 'red'
    case OrderStatus.ON_THE_WAY:
      return 'blue'
    case OrderStatus.STARTED:
      return 'green'
    case OrderStatus.COMPLETED:
      return 'green'
    case OrderStatus.PAID:
      return 'green'
    case OrderStatus.CARD_DECLINED:
      return 'green'
    default:
      return 'gray'
  }
}

// Order Progress Status Colors
export const getOrderProgressStatusColor = (status: OrderProgressStatus) => {
  switch (status) {
    case OrderProgressStatus.PENDING:
      return 'gray'
    case OrderProgressStatus.APPROVED:
      return 'green'
    case OrderProgressStatus.REJECTED:
      return 'red'
    default:
      return 'gray'
  }
}
