import React from 'react';
import Error from './componets/error';
import { 
 BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from './componets/home';

function App() {
  return (
    <section className='flex flex-col max-w-lg m-auto'>
        <>
          <Router>
            <Routes>
              <Route path="/:id" element={<Home/>}/>
              <Route path='*' element={<Error />} />
            </Routes>
          </Router>


        </>
    </section>
  );
}

export default App;
