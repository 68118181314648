import React, { useContext, useEffect, useState } from 'react'

import { AppCtx } from './home'

const Invoice: React.FC = () => {
  const context = useContext(AppCtx)
  const  [costServices,setCostServices] = useState(0) 
  const numberCars:any = context?.orderStaffCars.length == 0 ? context?.orderCars?.length: context?.orderStaffCars.length
  const totalCostServices = costServices*numberCars
  let  tax:any = context?.tax
  const  totalTax = (totalCostServices*tax)/100

  useEffect(()=>{
    let totalServicesCost:any = 0
    context?.services.map( serv => totalServicesCost +=  serv.price)
    setCostServices(totalServicesCost)           
  },[])
  return (

    <div className='bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-3  pt-3 space-y-5 ' >
      <div className='flex relative'>
        <div className='text-black font-medium ml-5 text-xl '>Service Cost</div>
        <div className='absolute right-0  mr-5 text-gray-400'>
          <span className='text-gray-400 text-xl mr-2'>
            {totalCostServices}</span>
            AED
        </div>     
       </div>
      <div className='flex relative'>
        <div className='ml-5 font-medium text-lg'>
        VAT (5%)
          </div>
        <div className='absolute right-0  mr-5 text-gray-400'>
          <span className='text-gray-400 text-xl mr-2'>{totalTax}</span>
          AED
        </div>
      </div>
      <div className='flex relative'>
        <div className='ml-5 text-yellow-400  text-xl font-medium'>
          Total
          </div>
        <div className='absolute right-0  mr-5 text-gray-400'>
          <span className='text-black font-medium text-xl mr-2'>{context?.totalValue}</span>
          AED
        </div>
      </div>
    </div>
  )
}

export default Invoice
