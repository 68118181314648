import React, { useContext } from 'react'
import { AppCtx, Config } from './home'
import { OrderStatus } from '../models/order/enum'



const Header: React.FC = () => {
  const context = useContext(AppCtx)
  const config = useContext(Config)
  return (
    <>
      {context && <div className='bg-white relative rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-3 pt-3 space-y-2 ' >
        <div className='flex relative'>
          <div className='text-black font-bold ml-5 text-2xl '>
            {
              context.status == OrderStatus.PAID ? <div>  TAX INVOICE</div> : <div> Order Receipt</div>
            }
          </div>
          <img src="1.png" className='w-2/6  absolute top-1 h-20 object-contain right-1' alt="" />
        </div>
        <div className='flex relative'>
          <div className='ml-5'>
            Vi7 Group
          </div>
        </div>
        <div className='flex relative'>
          <div className='ml-5'>
            <>
              {config.key.toUpperCase()} :{config.value}
            </>
          </div>
        </div>

      </div>}</>

  )
}

export default Header
