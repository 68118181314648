import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IConfig } from '../models/config/response'
import { IOrder } from '../models/order/response'
import CardCar from './card-car'
import Error from './error'
import Footer from './footer'
import Header from './header'
import Invoice from './invoice'
import Order from './order'
import Service from './service'
import ServiceCenter from './service-center'
import Spanner from './spanner'
import StaffInfo from './stuff-info'


export const AppCtx = React.createContext<IOrder | null>(null);
export const Config = React.createContext<IConfig>({ key: "", value: "" });

function Home() {

    const [user, setUser] = useState<IOrder | null>(null)
    const [config, setConfig] = useState<IConfig>({ key: "", value: "" })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { id } = useParams();

    const getUser = async (): Promise<IOrder> => {
        try {
            const res = await axios.get<IOrder>(`https://api.carbeez1.com/api/v1/orders/${id}/public-show`)
            const config = await axios.get<any>('https://api.carbeez1.com/api/v1/configs/get-trn')
            setUser(res?.data)
            setConfig(config.data)
            setLoading(true)
            return res.data;
        } catch (e) {
            setError(true)
            throw e
        }
    }
    useEffect(() => {
        getUser();
    }, [id])

    return (
        <>
            {error ? <Error /> :
                loading ?
                    <AppCtx.Provider value={user}>
                        <Config.Provider value={config}>
                            <Header />
                        </Config.Provider>
                        <Order />
                        <StaffInfo />
                        <ServiceCenter />
                        <CardCar />
                        <Service />
                        <Invoice />
                        <Footer />
                    </AppCtx.Provider>
                    : <Spanner />

            }

        </>

    )
}

export default Home