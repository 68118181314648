import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { AppCtx } from './home'
import { OrderStatus } from '../models/order/enum'
import moment from 'moment'



const Order: React.FC = () => {
  const context = useContext(AppCtx)
  const [color, setColor] = useState<string>()
  useEffect(() => {
    switch (context?.status) {
      case OrderStatus.PENDING:
        setColor('yallow')
        break;
      case OrderStatus.CANCELED:
        setColor('black')
        break;
      case OrderStatus.APPROVED:
        setColor('green')
        break;
      case OrderStatus.REJECTED:
        setColor('red')
        break;
      case OrderStatus.ON_THE_WAY:
        setColor('orange')
        break;
      case OrderStatus.STARTED:
        setColor('cyan')
        break;
      case OrderStatus.COMPLETED:
        setColor('purple')
        break;
      case OrderStatus.PAID:
        setColor('blue')
        break;
      case OrderStatus.CARD_DECLINED:
        setColor('red')
        break;
      default:
        break;
    }
  }, [context])

  return (
    <>
      {context && <div className='bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-3 pt-3 space-y-5 ' >
        <div className='flex relative'>
          <div className='text-black font-bold ml-5 text-2xl '>
            Order #{context?.id}

          </div>
          <div style={{backgroundColor:color}} className={`   rounded-xl w-auto  py-1 px-2 text-center absolute right-0 mr-5 text-white`}>
            {OrderStatus[context.status]}</div>
        </div>
        <div className='flex relative'>
          <div className='ml-5'>
            <FontAwesomeIcon icon={faClock} className='mr-2 text-xl text-yellow-400' />
            <> {moment(context.createdAt)?.format('DD-MM-YYYY, LT')}</>
          </div>
          <div className='absolute right-0  mr-5'>
            <span className='text-yellow-400 text-2xl mr-2'>{context.totalValue}</span>
            AED
          </div>
        </div>
      </div>}</>

  )
}

export default Order
