import React, { useContext, useEffect, useState } from 'react'
import { AppCtx } from './home'
import { OrderStatus } from '../models/order/enum'



const Footer: React.FC = () => {
    return (
        <>
            <div className='bg-white relative mb-5 rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-3 pt-3 space-y-5 ' >

                <div className='ml-3 mr-3'>
                    <div className='font-medium'>"Thank you for using CarBeez" </div>
                    <div>Please contact us for any questions, feedback or required support throught :  <a className='font-medium text-yellow-400' href="mailto:support@vi7group.com">support@vi7group.com</a> or visit <a className='font-medium text-yellow-400' href="https://vi7group.com"> vi7group.com</a>
                    </div>
                    {/* <a href="#" className='ml-2 text-blue-600 hover:text-red-600'   >Link</a> */}
                </div>
            </div>
        </>

    )
}

export default Footer
