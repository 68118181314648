import React, { useContext, useEffect } from 'react'
import { AppCtx } from './home'
function Innerwashing() {
  const context = useContext(AppCtx)
  return (
    <>
      {context?.services.map((serv , index) => {
        return (
            <section key={index} >
              <div className=' bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-1 pt-3 space-y-5 ' >
                <div className="ml-2">
                  <div className="">
                    <span className="text-yellow-400 text-2xl mr-1 font-medium">{serv?.price}</span> AED
                  </div>
                  <div className="font-medium text-xl mb-2">{serv.name}</div>
                  <div className='my-1'>{serv.additionalInformation}</div>
                </div>
              </div>
            </section>
        )
      })}
    </>
  )
}
export default Innerwashing