import { useContext } from "react"
import { AppCtx } from "./home"

function StaffInfo() {
    const context = useContext(AppCtx)

    return (
        <>
            <div className=' bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-1 pt-3 space-y-5 ' >
                <div className="flex">
                    <img className='h-20 w-20 rounded-full ml-3 mb-2' src={context?.staff?.image?.url} alt={context?.staff?.user.username} />
                    <div className='ml-5 '>
                        <div className='font-bold text-lg'>{context?.staff?.user?.firstName} {context?.staff?.user?.lastName}  </div>
                        <div className='text-base mt-2'>
                            {context?.staff?.permanentLocation?.name}
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default StaffInfo
