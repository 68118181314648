import { useContext } from "react"
import { AppCtx } from "./home"

function CardCar() {
    const context = useContext(AppCtx)
    const orderCars: any = context?.orderStaffCars.length == 0 ? context?.orderCars : context?.orderStaffCars

    return (<>
        {orderCars?.map((car: any, index: any) => {
            return (
                <div key={index}>
                    <div className=' bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-1 pt-3 space-y-5 ' >
                        <div className="flex flex-col">
                            <div className="flex">
                                <div className=''>
                                    <img className='h-20 w-20 rounded-xl ml-3'
                                        src={car?.brand?.parentBrand?.brandImage?.url}
                                        alt={car?.brand?.name} />
                                </div>
                                <div className='ml-5'>
                                    <div className='font-bold text-4xl'>{car.brand?.parentBrand?.name}</div>
                                    <div className='font-bold text-gray-500 text-base'>
                                        {car.brand?.name} | {car.year}
                                    </div>
                                    <div className="flex">
                                        <div className="font-medium text-lg"> Color </div>
                                        <div className=" mt-1.5 h-5 w-5 ml-3 rounded-full " style={{
                                            background: `${car?.carColor?.hex}`
                                        }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="   
                                     flex flex-row justify-between rounded-md border-gray-400 py-2 border-2   w-auto mx-4 px-4 items-center my-2 text-light-500 ">
                                    <div className=" flex-grow text-2xl font-medium text-gray-500">{car.plateCode} </div>
                                    <img src={car.city?.plateImage?.url} className='w-24 h-auto' alt="" />
                                    <div className="flex-grow text-2xl font-medium text-gray-500">{car.plateNumber} </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )


        })}
    </>

    )
}

export default CardCar
