import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { AppCtx } from "./home"

function ServiceCenter() {
    const context = useContext(AppCtx)
    return (
        <section>
            <div className=' divide-y divide-solid  divide-gray-300 bg-white rounded-xl drop-shadow-lg mt-5 mr-4 ml-4 pb-2 pt-2 space-y-5 ' >
                <div className='flex items-center '>
                    <img className='h-10 w-10 rounded-full ml-3' src="/icon.png" alt="user" />
                    <div className="ml-5">
                        <div className="  text-2xl font-medium ">
                            {context?.permanentLocation?.name}
                        </div>
                        <div>
                        {context?.permanentLocation?.additionalInformation}
                        </div>
                    </div>
                </div>
                <div className='flex items-center '>
                    <div className=' rounded-full ml-3' >
                        <FontAwesomeIcon icon={faCalendar} className='mr-2 text-2xl text-gray-500' />
                    </div>
                    <div className="ml-5 mt-2">
                        <div className="  text-xl font-medium ">
                            Date And Time
                        </div>
                        <div>
                            {context?.requiredDate} {context?.requiredTime}
                        </div>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default ServiceCenter
